<template>
  <div>
    <h3>Rolling Motion</h3>
    <p>
      Rolling motion is a combination of translational motion and rotational motion. We see a number of examples
      of rolling motion in our daily life, for example- a ball rolling, wheels of a vehicle rolling on the road
      etc. Rolling motion can occur with or without slipping (i.e., with or without relative motion at the
      point of contact of object with the surface).
    </p>
    <h3>Pure Rolling Motion</h3>
    <p>
      In pure rolling motion, there is no relative motion at the point of contact of the object in motion
      with the surface. Therefore, no friction or opposing force acts at the surface in contact with the
      object.
      Center of mass of the object travels with a velocity equal to the velocity of translation and the object
      rotates
      about its center of mass. An example of pure rolling motion of a disc of radius R is shown in the
      figure given below. Let us analyze its motion in detail.
    </p>
    <div class="w3-center">
      <img src="D:\Rolling_motion_1.png" width="400px"></img>
    </div>
    <p>
      The disc translates with a velocity \(v\) in forward direction and rotates with an angular velocity
      \({\omega}\) about
      its center. Since translation velocity of the disc is \(v\), velocity of its center of mass is also
      \(v\).
      Velocity of any point of the disc has two components, one is the translation velocity \(v\) in the same
      direction as the velocity of center of mass and other is the velocity on account of rotation of the disc
      in a direction perpendicular to the radius vector of the given point with respect to the center and
      magnitude equal to \(R{\omega}\). Net velocity of any point of the disc is the vector sum of these two
      velocities.
    </p>
    <p>
      As the given example is of pure rolling motion, the bottom of the disc must be at rest with respect to the
      surface, therefore
      $$v-R \omega =0
      \\ v=R \omega$$
      The direction of velocity of some other points of the disc are as shown in the figure.
    </p>
    <h3>Kinetic Energy of Rolling Motion</h3>
    <p>
      Now we will calculate kinetic energy of a body in rolling motion. It can be separated into two parts
      kinetic energy of translation and kinetic energy of rotation. Thus, total kinetic energy K is given as,
      $$
      \begin{equation} \label{eq1}
      \begin{split}
      K&=\text{Translational kinetic energy} + \text{Rotational Kinetic energy}
      \\&=\frac{1}{2}mv^2+\frac{1}{2}I{\omega}^2
      \end{split}
      \end{equation}
      $$
      Where m is the mass of the body, v is the velocity of center of mass, I is the moment of inertia and w is the
      angular velocity (about center) of the body.
      <br><br>For the above example of a disc,
      $$
      \begin{equation} \label{eq2}
      \begin{split}
      \text{Kinetic Energy} &=\frac{1}{2}mv^2+\frac{1}{2}I{\omega}^2
      \\ &=\frac{1}{2}mv^2+\frac{1}{2}(\frac{mr^2}{2})(\frac{v^2}{r^2})
      \\ &=\frac{1}{2}mv^2+\frac{1}{4}mv^2
      \\ &=\frac{3}{4}mv^2
      \end{split}
      \end{equation}
      $$
    </p>
    <h3>Instantaneous Axis of Rotation</h3>
    <p>
      Pure rolling motion can be simplified and assumed as pure rotational motion (with same \({\omega}\)) about
      an axis
      passing through the point of contact of the object and the surface and this axis is called instantaneous
      axis
      of rotation. Now instead of considering pure rolling motion as a combination of translation and
      rotational motion,
      it is considered only as pure rotational motion about instantaneous axis of rotation. This concept
      simplifies the
      study of rolling motion.
    </p>
    <p>
      We can calculate the kinetic energy of the body in a much simpler way by using
      only a single term as in the equation below.
      $$ \text{Kinetic Energy}=\frac{1}{2}I{\omega}^2$$
      where I is the moment of inertia of the disc about instantaneous axis of rotation.
      <br><br>Also, velocity of any point P can be calculated using instantaneous axis of rotation by a single term
      \(v=r{\omega}\) where r is the distance of point P from instantaneous axis of rotation and velocity is
      in a direction perpendicular to this line joining the point P and instantaneous axis of rotation in the direction of rotation.

      <br><br>In the example of the disc discussed above, line passing through the bottommost point and parallel to \({\omega}\) is
      the
      instantaneous axis of rotation.
    </p>
    <h3 ref="playgraph">
      MagicGraph | Rolling Motion
    </h3>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Free Body Diagram';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Free Body Diagram',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
