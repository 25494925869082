// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch
} from '../../../common/edliy_utils-circle';
const Boxes = {
box1: function () {
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-3, 20, 20, -3],
    keepaspectratio: true, axis:true, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.options.layer['image']=14;
brd1.suspendUpdate();
//Title
makeResponsive(brd1);
placeTitle(brd1, 'Rolling Motion of a Coin', 'Trajectory of a Point on the Coin');
//Sliders
brd1.suspendUpdate();

brd1.options.layer['image'] =14;
//Title and subtitle
//var title1 = brd1.create('text', [8, 18, '<b> Perimeter of Circle <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
//var radius = brd1.create('slider',[[15,12],[20,12],[1,2.5,3]], {snapToGrid: false ,face:'circle', size:3, name:'r', strokeColor:'black', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//placeImage(brd1,'/assets/one.svg', 2, 11.7, 0.6, 0);
//placeImage(brd1,'/assets/two.svg', 2, 9.7, 0.6, 0);
var Go =placeImage(brd1,'/assets/go.svg', 9, 6, 2, 0);
var radius =placeSlider(brd1, 1, 7, 1, 1.5, 2, 4, '&nbsp; &nbsp;R');
radius.setAttribute({snapWidth:0.25});
placeText(brd1, 8.5, 16, 20,  '1. Set the Location of Point \'P\' 2. Tap on Go button to let the coin roll')
//placeText(brd1, 6, 10, 20,  '1. Set the radius using the slider. 2. Tap on the Go button to roll the circle.')
//placeText(brd1, 3, 6, 20, ()=>'L =2 &pi; R ='+(2*Math.PI*radius.Value()).toFixed(2));
var segment1 = brd1.create('segment', [[0,function(){return radius.Value()}], [function(){return 4*Math.PI*radius.Value()},function(){return radius.Value()}]], {fixed: true, strokeColor: 'black', visible:false});
//var ptCenter = brd1.create('glider', [0,2, segment1],{face:'o' , name:'Drag Me!', label:{fontSize:function(){return 16*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var ptCenter = placeGlider(brd1, segment1, 0, 2, 3,  'red', 'black');
//ptCenter.setLabel('Drag me!');
var r =placePoint(brd1, ()=>ptCenter.X(), ()=>ptCenter.Y()/2,0, 'red','black');
r.setLabel('R');
r.label.setAttribute({anchorX:'middle', anchorY:'middle'});
var pt1 =brd1.create('point', [function(){return ptCenter.X()},0.0], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
radius.on('down', function(){ptCenter.moveTo([0,radius.Value()]); pt1.moveTo([0,0]);});
var c1 = brd1.create('circle', [ptCenter, pt1], {strokeColor: 'black', strokeWidth: 0.5, fillColor: 'orange', fillOpacity: 0.2});
function angle1(){
    return  (2*Math.PI-ptCenter.X()/radius.Value() );
}

var pt3 =brd1.create('point',
[function(){return ptCenter.X()+radius.Value()*Math.sin(angle1())},function(){return ptCenter.Y()-radius.Value()*Math.cos(angle1())}], {trace:true, fixed:true, visible:true, face:'circle', size:3, name:'',  strokeColor: 'black', fillColor: 'white'});

placeArrow(brd1, [()=>ptCenter.X(), ()=>ptCenter.Y()], [()=>pt3.X(), ()=>pt3.Y()], 2, 'black');
//var txt1 = brd1.create('text', [3, -4,  function(){return 'ANGLE = ' + angle1()*180/Math.PI}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

var segment2 = brd1.create('segment', [[0,0], [function(){return pt1.X()},0]], {fixed: true, strokeWidth:4, strokeColor: 'green'});

var arc1 = brd1.create('arc', [ptCenter, pt1, pt3], {strokeColor: 'green', strokeWidth:4});


//Arrow
var ptArrow1 =brd1.create('point', [function(){return pt1.X()} ,-2], {fixed:true, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
var ptArrow2 =brd1.create('point', [0 ,-2], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
var segment2 = brd1.create('segment', [ptArrow1, ptArrow2], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                highlightStrokeWidth: 2,
                                                                                                                lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                touchLastPoint: false,
                                                                                                                firstArrow: {type: 2, size: 8}
                                                                                                                });

var txtPer = brd1.create('text', [function(){return pt1.X()/2}, -1.5,  function(){return pt1.X().toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
Go.on('down', function(){ptCenter.moveTo([4*Math.PI*radius.Value(), radius.Value()], 1000)});

//var ptArrow3 =brd1.create('point', [-5,0], {fixed:true, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
//var ptArrow4 =brd1.create('point', [-5, function(){return radius.Value()}], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
//var segment3 = brd1.create('segment', [ptArrow3, ptArrow4], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
//                                                                                                                highlightStrokeWidth: 2,
//                                                                                                                lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                //touchLastPoint: false,
                                                                                                                //firstArrow: {type: 2, size: 8}
                                                                                                                //})
//var txtRad = brd1.create('text', [-4, function(){return radius.Value()/2},  function(){return radius.Value().toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

brd1.unsuspendUpdate();
}
}
export default Boxes;
